<template>
    <div class="detection_url_wrap">
        <!--        <div @click="movePage('/detection/request')">의뢰하기</div>-->
        <table class="du_table" width="100%"  border="0" cellspacing="0" cellpadding="0"
               style = "table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="140px" />
                <col width="50px" />
                <col width="530px" />
                <col width="180px" />
                <col width="130px" />
                <col width="170px" />
            </colgroup>
            <tr class="du_header">
                <td colspan="1">{{ $t('en_no') }}</td>
                <td colspan="1"></td>
                <td colspan="1">{{ $t('ignore_url2') }}</td>
                <td colspan="1">{{ $t('url_detection_result') }}</td>
                <td colspan="1">{{ $t('reg_date') }}</td>
                <td colspan="1">{{ $t('except_cancel') }}</td>
            </tr>
            <tr class="du_list" v-for="(detect, index) in detectionData" :key="`url${index}`">
                <td colspan="1">{{ tableIdx(index)}}</td>
                <td colspan="1"></td>
                <td colspan="1" class="url_result">{{ detect.di_text }}</td>
                <td colspan="1">{{ numFormat(detect.di_cnt) }}</td>
                <td colspan="1">{{ returnDateFormat(detect.created_at, 'YYYY.MM.DD') }}</td>
                <td colspan="1">
                    <span class="except_cancel cursor" @click="openDeleteConfirm(detect.di_idx)">{{ $t('except_cancel') }}</span>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import {detectionDomainStatus} from "@/service/detectionService";
import dateMixins from "@/mixins/dateMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "DetectionListUrlLayout",
    mixins: [dateMixins, alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        detectionData: {default: () => []},
        itemData: {default: () => {}},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
            selectCollapse:'',
            searchOption: 'permission',
            detectionDomainStatus: detectionDomainStatus,

            clickIgnore: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        openUrl(url) {
            window.open(url, '_blank');
            //location.href= url;
        },
        numFormat(num) {
            return util.isEmpty(num) || isNaN(num) ? 0 : util.Number.numFormat(num);
        },
        openDeleteConfirm(key) {
            this.createConfirm({
                content: this.$t('d_ignore_url_delete_confirm'),
                confirm: () => {
                    EventBus.$emit('getDetectionDelete', key);
                }
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
